import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  getDepartmentList,
  getUsersByDepartment,
  getUserRentalRecordsByDepartment,
} from "../../../api/equipmentApi";
import Loading from "../../Loading/Loading";
import PaginationComponent from "../../../utils/PaginationComponent"; // Pagination 컴포넌트
import HistoryListBody from "./HistoryListBody";
import "./HistoryList.css";
import UseIsMobile from "../useIsMobile";
import HistoryListCard from "../Card/HistoryListCard";

export default function HistoryList() {
  const [data, setData] = useState([]); // 대여 기록 데이터
  const [departments, setDepartments] = useState([]); // 부서 목록
  const [users, setUsers] = useState([]); // 사용자 목록
  const [selectedDepartment, setSelectedDepartment] = useState(""); // 선택된 부서
  const [selectedUser, setSelectedUser] = useState(""); // 선택된 사용자
  const [loading, setLoading] = useState(false); // 로딩 상태
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  const itemsPerPage = 8; // 페이지당 항목 수

  // 날짜 포맷 함수
  const formatDate = (date) => moment(date).format("YYYY-MM-DD");

  const isMobile = UseIsMobile();

  // 부서 목록 조회
  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true);
      try {
        const response = await getDepartmentList();
        if (response.data.result) {
          setDepartments(response.data.data);
        }
      } catch (err) {
        console.error("네트워크 오류로 부서 목록을 가져오지 못했습니다.");
      } finally {
        setLoading(false);
      }
    };
    fetchDepartments();
  }, []);

  // 부서 선택 시 사용자 목록을 가져오는 함수
  const handleDepartmentChange = async (event) => {
    const departmentId = event.target.value;
    setSelectedDepartment(departmentId);
    setSelectedUser(""); // 부서 변경 시 사용자는 초기화
    setData([]); // 대여 기록 초기화
    setUsers([]); // 사용자 목록 초기화

    if (departmentId) {
      setLoading(true);
      try {
        const response = await getUsersByDepartment(departmentId);
        if (response.data.result) {
          setUsers(response.data.data);
        }
      } catch (err) {
        console.error("네트워크 오류로 사용자 목록을 가져오지 못했습니다.");
      } finally {
        setLoading(false);
      }
    }
  };

  // 사용자 선택 시
  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  // 대여 기록 조회 함수
  const fetchRecords = async () => {
    if (!selectedDepartment || !selectedUser) {
      alert("부서와 사용자를 선택하세요.");
      return;
    }

    setLoading(true);
    try {
      const response = await getUserRentalRecordsByDepartment(selectedUser);
      if (response.data.result) {
        setData(response.data.data);
      } else {
        setData([]);
      }
    } catch (err) {
      console.error("네트워크 오류로 대여 기록을 불러오지 못했습니다.");
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  // 페이지 변경 시 스크롤 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // 페이지 변경 핸들러
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 현재 페이지에 해당하는 데이터
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="history-list-container">
      <p className="history-title">대여 기록</p>
      <div className="history-list-total">
        <div className="filters">
          <div className="filter">
            <label htmlFor="department">부서</label>
            <select
              id="department"
              value={selectedDepartment}
              onChange={handleDepartmentChange}
            >
              <option value="">선택</option>
              {departments.map((dept) => (
                <option key={dept.id} value={dept.id}>
                  {dept.name}
                </option>
              ))}
            </select>
          </div>

          <div className="filter">
            <label htmlFor="user">사용자</label>
            <select
              id="user"
              value={selectedUser}
              onChange={handleUserChange}
              disabled={!selectedDepartment}
            >
              <option value="">선택</option>
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>

          <button className="search-button" onClick={fetchRecords}>
            검색
          </button>
        </div>

        <div>
          {loading ? (
            <div className="loading-container">
              <Loading />
            </div>
          ) : (
            <>
              {isMobile ? (
                <HistoryListCard
                  data={data}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  formatDate={formatDate}
                />
              ) : (
                <>
                  <table className="history-table">
                    <thead>
                      <tr>
                        <th>번호</th>
                        <th>제품명</th>
                        <th>모델명</th>
                        <th>시리얼 번호</th>
                        <th>IMEI</th>
                        <th>OS버전</th>
                        <th>OneUi버전</th>
                        <th>상세설명</th>
                        <th>대여일</th>
                        <th>반납일</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length === 0 ? (
                        <tr>
                          <td colSpan="10" className="history-center-text">
                            대여 기록이 없습니다.
                          </td>
                        </tr>
                      ) : (
                        <HistoryListBody
                          data={paginatedData}
                          currentPage={currentPage}
                          itemsPerPage={itemsPerPage}
                          formatDate={formatDate}
                        />
                      )}
                    </tbody>
                  </table>
                </>
              )}
              <PaginationComponent
                currentPage={currentPage}
                totalItems={data.length}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
