import styled from "styled-components";

export const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .pagination li {
    display: inline-block;
    margin: 0 5px;
  }

  .pagination li a {
    text-decoration: none;
    padding: 8px 12px;
    border: 1px solid #ddd;
    color: #337ab7;
    font-size: 1rem;
  }

  .pagination li.active a {
    background-color: #337ab7;
    color: white;
  }

  .pagination li a:hover {
    background-color: #f0f0f0;
  }

  .pagination li.disabled a {
    cursor: not-allowed;
    color: #ddd;
    background-color: #f1f1f1;
  }
`;
