import React from "react";
import Pagination from "react-js-pagination";
import { PaginationBox } from "./PaginationStyles";

const PaginationComponent = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) => {
  return (
    <PaginationBox>
      <Pagination
        activePage={currentPage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={totalItems}
        pageRangeDisplayed={5}
        onChange={onPageChange}
      />
    </PaginationBox>
  );
};

export default PaginationComponent;
