import React from "react";
import moment from "moment";
import "moment/locale/ko";
import "./RentalListCard.css";

const RentalListCard = ({ data, currentPage, itemsPerPage, handleRent }) => {
  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  return (
    <div className="rental-card-list">
      {data.map((item, index) => {
        const itemNumber = (currentPage - 1) * itemsPerPage + index + 1;

        return (
          <div className="rental-card-container" key={item.id}>
            <div className="rental-card-header">
              <p className="rental-card-number">#{itemNumber}</p>
              <p className="rental-card-user">
                사용자: <strong>{item.userName || "오류"}</strong>
              </p>
            </div>
            <div className="rental-card-body">
              <p className="rental-card-title">
                <strong>제품명:</strong> {item.product_name || "N/A"}
              </p>
              <p className="rental-card-content">
                <strong>모델명:</strong> {item.model_name || "N/A"}
              </p>
              <p className="rental-card-content">
                <strong>시리얼 번호:</strong> {item.serial_number || "N/A"}
              </p>
              <p className="rental-card-content">
                <strong>IMEI:</strong> {item.imei_number || "N/A"}
              </p>
              <p className="rental-card-content">
                <strong>OS버전:</strong> {item.os_version || "N/A"}
              </p>
              <p className="rental-card-content">
                <strong>OneUI버전:</strong> {item.one_ui_version || "N/A"}
              </p>
              <p className="rental-card-content">
                <strong>상세설명:</strong> {item.description || "-"}
              </p>
            </div>
            <div className="rental-card-footer">
              <p className="rental-card-content-point">
                <strong>대여일:</strong> {formatDate(item.rentalAt)}
              </p>
              {item.status === "RENTED" || !item.status ? (
                <button onClick={() => handleRent(item.userId, item.id)}>
                  반납
                </button>
              ) : (
                <span>오류</span>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RentalListCard;
