import React from "react";
import "./HistoryListCard.css";

const HistoryListCard = ({ data, currentPage, itemsPerPage, formatDate }) => {
  if (!data || data.length === 0) {
    return (
      <div className="no-records">
        <p>대여 기록이 없습니다.</p>
      </div>
    );
  }

  return (
    <div className="card-container">
      {data.map((item, index) => {
        const itemNumber = (currentPage - 1) * itemsPerPage + index + 1;

        return (
          <div className="card" key={item.id || item.product_name}>
            <div className="card-header">
              <h3>{item.product_name || "제품명 없음"}</h3>
              <p>#{itemNumber}</p>
            </div>
            <div className="card-body">
              <p>
                <strong>모델명:</strong> {item.model_name || "N/A"}
              </p>
              <p>
                <strong>시리얼 번호:</strong> {item.serial_number || "N/A"}
              </p>
              <p>
                <strong>IMEI:</strong> {item.imei_number || "N/A"}
              </p>
              <p>
                <strong>OS 버전:</strong> {item.os_version || "N/A"}
              </p>
              <p>
                <strong>OneUI 버전:</strong> {item.one_ui_version || "N/A"}
              </p>
              <p>
                <strong>상세설명:</strong> {item.description || "-"}
              </p>
              <p>
                <strong>대여일:</strong> {formatDate(item.rentalAt) || "오류"}
              </p>
              <p>
                <strong>반납일:</strong>{" "}
                {item.returnAt ? formatDate(item.returnAt) : "대여중"}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HistoryListCard;
