import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HistoryList from "./pages/Equipment/HistoryList/HistoryList";
import RentalList from "./pages/Equipment/RentalList/RentalList";
import AvailableList from "./pages/Equipment/AvailableList/AvailableList";
import Header from "./components/Header";
import "./App.css"; // 스타일 추가

const App = () => {
  //console.log("REACT_APP_API_ROOT:", process.env.REACT_APP_API_ROOT); //깃허브 시크릿 키가 잘 적용되는 지 확인하는 단계
  return (
    <Router>
      {/* 고정된 Header */}
      <Header />
      {/* Header 아래로 페이지 이동 */}
      <div className="app-content">
        <Routes>
          <Route path="/" element={<AvailableList />} />
          <Route path="/rentallist" element={<RentalList />} />
          <Route path="/historylist" element={<HistoryList />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
