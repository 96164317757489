import React, { useEffect, useState } from "react";
import { getAvailableEquipment } from "../../../api/equipmentApi";
import Loading from "../../Loading/Loading";
import AvailableListBody from "./AvailableListBody";
import AvailableModal from "./AvailableModal";
import PaginationComponent from "../../../utils/PaginationComponent"; // Import PaginationComponent
import "./AvailableList.css";
import AvailableListCard from "../Card/AvailableListCard"; // Import card component
import UseIsMobile from "../useIsMobile";

// 대여 가능한 리스트
export default function AvailableList() {
  const [data, setData] = useState([]); // 데이터 상태
  const [error, setError] = useState(null); // 오류 상태
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [selectedEquipment, setSelectedEquipment] = useState(null); // 선택된 장비 상태
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태
  const itemsPerPage = 8; // 페이지당 항목 수

  // 페이지네이션된 데이터 계산
  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // 페이지 변경 시 처리
  const handlePageChange = (page) => setCurrentPage(page);

  // 모달 열기
  const openModal = (equipment) => setSelectedEquipment(equipment);

  // 모달 닫기
  const closeModal = () => setSelectedEquipment(null);

  // 데이터 fetching
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAvailableEquipment();
        if (response.data.result) {
          setData(response.data.data); // 데이터 상태 업데이트
        } else {
          setError("데이터 조회 실패");
        }
      } catch (error) {
        setError("네트워크 오류로 데이터를 불러올 수 없습니다.");
      } finally {
        setLoading(false); // 로딩 상태 false로 설정
      }
    };
    fetchData();
  }, []); // 컴포넌트가 처음 렌더링 될 때만 실행

  const isMobile = UseIsMobile();

  // 페이지 변경 시 스크롤 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <>
      <p className="title">대여 가능한 장비</p>
      <div className="list-total">
        {/* 로딩 상태 처리 */}
        {loading ? (
          <div className="loading-container">
            <Loading />
          </div>
        ) : error ? (
          <p className="error-text">{error}</p>
        ) : isMobile ? (
          // 모바일 환경: 카드 컴포넌트 렌더링
          <AvailableListCard
            data={paginatedData}
            currentPage={currentPage}
            openModal={openModal}
            itemsPerPage={itemsPerPage}
          />
        ) : (
          // PC 환경: 테이블 컴포넌트 렌더링
          <>
            <table>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>제품명</th>
                  <th>모델명</th>
                  <th>시리얼 번호</th>
                  <th>IMEI</th>
                  <th>OS버전</th>
                  <th>OneUi버전</th>
                  <th>상세설명</th>
                  <th>대여</th>
                </tr>
              </thead>
            </table>
            <div className="table-body-container">
              <table className="table-body">
                <tbody>
                  <AvailableListBody
                    paginatedData={paginatedData}
                    currentPage={currentPage}
                    openModal={openModal}
                    itemsPerPage={itemsPerPage}
                  />
                </tbody>
              </table>
            </div>
          </>
        )}

        {/* 페이지네이션 컴포넌트 */}
        <PaginationComponent
          currentPage={currentPage}
          totalItems={data.length}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
        />

        {/* 상세 모달 */}
        <AvailableModal
          isOpen={selectedEquipment !== null}
          closeModal={closeModal}
          selectedEquipment={selectedEquipment}
        />
      </div>
    </>
  );
}
