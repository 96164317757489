import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import MyDatePicker from "../../../components/MyDatePicker";
import {
  getDepartmentList,
  getUsersByDepartment,
  postRentEquipment,
} from "../../../api/equipmentApi";
import "./AvailableModal.css";

Modal.setAppElement("#root");

const AvailableModal = ({ isOpen, closeModal, selectedEquipment }) => {
  const [options, setOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedUserOption, setSelectedUserOption] = useState("");
  const [rentalDate, setRentalDate] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchDepartmentList();
    }
  }, [isOpen]);

  const fetchDepartmentList = async () => {
    try {
      const response = await getDepartmentList();
      if (response.data.result) {
        setOptions(response.data.data);
      } else {
        alert("부서 조회 실패: " + response.data.message);
      }
    } catch (error) {
      alert("부서 조회 실패: 네트워크 오류");
    }
  };

  const fetchUserOption = async (departmentId) => {
    try {
      const response = await getUsersByDepartment(departmentId);
      if (response.data.result) {
        setUserOptions(response.data.data);
      } else {
        alert("사용자 조회 실패: " + response.data.message);
      }
    } catch (error) {
      alert("사용자 조회 실패: 네트워크 오류");
    }
  };

  const handleDepartmentChange = (event) => {
    const departmentId = event.target.value;
    setSelectedOption(departmentId);
    fetchUserOption(departmentId);
  };

  const handleUserChange = (event) => {
    setSelectedUserOption(event.target.value);
  };

  const handleDateChange = (date) => {
    setRentalDate(date);
  };

  const handleRent = async () => {
    if (!selectedOption || !selectedUserOption || !rentalDate) {
      alert("모든 항목을 선택해주세요.");
      return;
    }

    try {
      const response = await postRentEquipment({
        equipmentId: selectedEquipment,
        departmentId: selectedOption,
        userId: selectedUserOption,
        rentalAt: rentalDate.toISOString(),
      });

      if (response.data.result) {
        alert("대여 성공");
        window.location.reload();
        closeModal();
      } else {
        alert("대여 실패: " + response.data.message);
      }
    } catch (error) {
      alert("대여 실패: 네트워크 오류");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="modal-container"
      overlayClassName="modal-overlay"
    >
      <h3 className="modal-title">대여 정보</h3>
      <div className="modal-content">
        <div className="select-option">
          <div className="select-item">
            <label>부서</label>
            <select value={selectedOption} onChange={handleDepartmentChange}>
              <option value="">선택</option>
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <div className="select-item">
            <label>사용자</label>
            <select
              value={selectedUserOption}
              onChange={handleUserChange}
              disabled={!selectedOption}
            >
              <option value="">선택</option>
              {userOptions.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>

          <div className="select-item">
            <label>대여일</label>
            <MyDatePicker
              selected={rentalDate}
              onChange={handleDateChange}
              disabled={!selectedUserOption}
              className="datepicker-input"
            />
          </div>
        </div>

        <div className="modal-actions">
          <button onClick={closeModal} className="action-button close-button">
            닫기
          </button>
          <button onClick={handleRent} className="action-button rent-button">
            대여
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AvailableModal;
