import React from "react";
import "./AvailableListCard.css";

export default function AvailableListCard({
  data,
  openModal,
  currentPage,
  itemsPerPage,
}) {
  return (
    <div className="available-card-list">
      {data.map((item, index) => {
        const itemNumber = (currentPage - 1) * itemsPerPage + index + 1;
        return (
          <div className="available-card-container" key={item.id}>
            <div className="available-card-header">
              <p className="available-card-number">#{itemNumber}</p>
              <p className="available-card-title">
                <strong>제품명:</strong> {item.product_name || "N/A"}
              </p>
            </div>
            <div className="available-card-body">
              <p className="available-card-content">
                <strong>모델명:</strong> {item.model_name || "N/A"}
              </p>
              <p className="available-card-content">
                <strong>시리얼 번호:</strong> {item.serial_number || "N/A"}
              </p>
              <p className="available-card-content">
                <strong>IMEI:</strong> {item.imei_number || "N/A"}
              </p>
              <p className="available-card-content">
                <strong>OS버전:</strong> {item.os_version || "N/A"}
              </p>
              <p className="available-card-content">
                <strong>OneUI버전:</strong> {item.one_ui_version || "N/A"}
              </p>
              <p className="available-card-content">
                <strong>상세설명:</strong> {item.description || "-"}
              </p>
            </div>
            <div className="available-card-footer">
              <button onClick={() => openModal(item.id)}>대여</button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
