import React, { useEffect, useState } from "react";
import {
  getBorrowedEquipment,
  putReturnEquipment,
} from "../../../api/equipmentApi";
import SideTopButton from "../../Scroll/SideTopButton";
import RentalListBody from "./RentalListBody";
import Loading from "../../Loading/Loading"; // 로딩 컴포넌트 가져오기
import PaginationComponent from "../../../utils/PaginationComponent"; // 분리된 Pagination 컴포넌트 가져오기
import RentalListCard from "../Card/RentalListCard"; // 카드 컴포넌트 가져오기
import styles from "./RentalList.module.css"; // Import CSS Module
import UseIsMobile from "../useIsMobile";

export default function RentalList() {
  const [data, setData] = useState([]); // 대여 중인 장비 데이터
  const [error, setError] = useState(null); // 오류 상태
  const [loading, setLoading] = useState(true); // 로딩 상태

  // Pagination 관련 상태
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // 페이지당 아이템 수

  const paginatedData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const isMobile = UseIsMobile();

  // 대여 중인 장비 리스트를 불러오는 함수
  useEffect(() => {
    borrowedEquipmentList();
  }, []); // 컴포넌트가 처음 렌더링될 때만 데이터 불러오기

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 변경 시 스크롤을 맨 위로 초기화
  }, [currentPage]);

  const borrowedEquipmentList = async () => {
    try {
      setLoading(true);
      const response = await getBorrowedEquipment();
      if (response.data.result) {
        setData(response.data.data); // 데이터 상태 업데이트
      } else {
        setError("대여 중인 장비 List를 불러올 수 없습니다.");
      }
    } catch (error) {
      setError("데이터를 불러올 수 없습니다.");
    } finally {
      setLoading(false);
    }
  };

  const handleRent = async (userId, id) => {
    try {
      const rentalRequest = { userId, equipmentId: id };
      if (!userId || !id) {
        alert("사용자 ID 또는 장비 ID를 확인하세요.");
        return;
      }

      const response = await putReturnEquipment(rentalRequest);

      if (response.data.result) {
        alert("반납을 성공했습니다.");
        borrowedEquipmentList(); // 반납 후 데이터를 다시 불러오기
      } else {
        alert("반납 실패: " + response.data.message);
      }
    } catch (error) {
      console.error("반납 처리 중 오류 발생:", error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <p className={styles.title}>대여 중인 장비</p>
      <div className={styles["list-total"]}>
        {loading ? (
          <div className={styles["loading-container"]}>
            <Loading />
          </div>
        ) : error ? (
          <p className={styles["error-text"]}>{error}</p>
        ) : isMobile ? (
          <RentalListCard
            data={paginatedData}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            handleRent={handleRent}
          />
        ) : (
          <table className={styles["table-container"]}>
            <thead>
              <tr>
                <th>번호</th>
                <th>제품명</th>
                <th>모델명</th>
                <th>시리얼 번호</th>
                <th>IMEI</th>
                <th>OS버전</th>
                <th>OneUi버전</th>
                <th>상세설명</th>
                <th>사용자</th>
                <th>대여일</th>
                <th>반납</th>
              </tr>
            </thead>
            <RentalListBody
              data={paginatedData}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              handleRent={handleRent}
            />
          </table>
        )}
        <PaginationComponent
          currentPage={currentPage}
          totalItems={data.length}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
        />
        <SideTopButton />
      </div>
    </>
  );
}
