import React from "react";
import "./AvailableList.css";

const AvailableListBody = ({
  paginatedData,
  currentPage,
  openModal,
  itemsPerPage,
}) => {
  // **데스크톱 UI**: 테이블 형식
  return (
    <>
      {paginatedData.map((item, index) => {
        const itemNumber = (currentPage - 1) * itemsPerPage + index + 1;
        return (
          <tr key={item.id}>
            <td>{itemNumber}</td>
            <td>{item.product_name || "N/A"}</td>
            <td>{item.model_name || "N/A"}</td>
            <td>{item.serial_number || "N/A"}</td>
            <td>{item.imei_number || "N/A"}</td>
            <td>{item.os_version || "N/A"}</td>
            <td>{item.one_ui_version || "N/A"}</td>
            <td>{item.description || "-"}</td>
            <td>
              <button onClick={() => openModal(item.id)}>대여</button>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default AvailableListBody;
