import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Header.css";

/**상단 메뉴 바 컴포넌트 */
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation(); // 현재 경로 확인

  // 해당 페이지에 대해 해당하는 상단 메뉴의 버튼을 누르면, 새로고침 되도록 하기 위해 필요한 부분분
  const handleNavigation = (path) => {
    if (location.pathname === path) {
      window.location.reload(); // 현재 페이지에서 새로 고침
    } else {
      navigate(path, { replace: true });
    }
  };

  return (
    <div className="header-container">
      <div className="menu-bar">
        <button
          className="header-button-style"
          onClick={() => handleNavigation("/")}
        >
          대여
        </button>
        <button
          className="header-button-style"
          onClick={() => handleNavigation("/rentallist")}
        >
          반납
        </button>
        <button
          className="header-button-style"
          onClick={() => handleNavigation("/historylist")}
        >
          기록
        </button>
      </div>
    </div>
  );
};

export default Header;
