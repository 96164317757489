import React from "react";
import moment from "moment";
import "moment/locale/ko";

const RentalListBody = ({ data, currentPage, itemsPerPage, handleRent }) => {
  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  return (
    <tbody>
      {data.map((item, index) => {
        const itemNumber = (currentPage - 1) * itemsPerPage + index + 1;
        return (
          <tr key={item.id}>
            <td>{itemNumber}</td>
            <td>{item.product_name || "N/A"}</td>
            <td>{item.model_name || "N/A"}</td>
            <td>{item.serial_number || "N/A"}</td>
            <td>{item.imei_number || "N/A"}</td>
            <td>{item.os_version || "N/A"}</td>
            <td>{item.one_ui_version || "N/A"}</td>
            <td>{item.description || "-"}</td>
            <td>{item.userName || "오류"}</td>
            <td>{formatDate(item.rentalAt)}</td>
            <td>
              {item.status === "RENTED" || !item.status ? (
                <button onClick={() => handleRent(item.userId, item.id)}>
                  반납
                </button>
              ) : (
                "오류"
              )}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default RentalListBody;
