import styled from "styled-components";

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

export const LoadingText = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px; /* 텍스트와 스피너 간의 간격 조정 */
`;
