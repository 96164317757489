import styles from "./TopButton.module.css";
import { useState, useEffect } from "react";
import { FaArrowAltCircleUp } from "react-icons/fa";

export default function SideTopButton() {
  const [showButton, setShowButton] = useState(false); // 스크롤 내렸을 때만 위로 갈 수 있는 버튼이 생성되도록 작성.

  const handleScroll = () => {
    if (!window.scrollY) return;

    window.scrollTo({
      //부드럽게 상단으로 스크롤함.
      top: 0,
      behavior: "smooth", //부드러운 스크롤 애니메이션 활성화
    });
  };

  useEffect(() => {
    const handleShowButton = () => {
      console.log("scroll Y:", window.innerHeight);
      if (window.scrollY > 20) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleShowButton);
    return () => {
      window.removeEventListener("scroll", handleShowButton);
    };
  }, []);

  return (
    <div className={styles.toBtn_wrap}>
      {showButton && (
        <button className={styles.topBtn} onClick={handleScroll}>
          <FaArrowAltCircleUp />
        </button>
      )}
    </div>
  );
}
