import React from "react";
import { Background, LoadingText } from "./Styles";
import Spinner from "./SpinnerLoding.gif";

/**로딩 컴포넌트 */
const Loading = () => {
  return (
    <Background>
      <LoadingText>잠시만 기다려 주세요.</LoadingText>
      <img
        src={Spinner}
        alt="로딩중"
        style={{ maxWidth: "100px", height: "auto" }}
      />
    </Background>
  );
};

export default Loading;
