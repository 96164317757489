import React, { useEffect } from "react";
import "./HistoryList.css";

const HistoryListBody = ({ data, currentPage, itemsPerPage, formatDate }) => {
  useEffect(() => {
    // console.log("Current Page:", currentPage);
    //console.log("Data length:", data.length);
  }, [data, currentPage]);

  if (data.length === 0) {
    return (
      <tr>
        <td colSpan="10" className="history-center-text">
          대여 기록이 없습니다.
        </td>
      </tr>
    );
  }

  return (
    <>
      {data.map((item, index) => (
        <tr key={item.id}>
          <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
          <td>{item.product_name || "N/A"}</td>
          <td>{item.model_name || "N/A"}</td>
          <td>{item.serial_number || "N/A"}</td>
          <td>{item.imei_number || "N/A"}</td>
          <td>{item.os_version || "N/A"}</td>
          <td>{item.one_ui_version || "N/A"}</td>
          <td>{item.description || "-"}</td>
          <td>{formatDate(item.rentalAt) || "오류"}</td>
          <td>{item.returnAt ? formatDate(item.returnAt) : "대여중"}</td>
        </tr>
      ))}
    </>
  );
};

export default HistoryListBody;
