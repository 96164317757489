import axios from "axios";

export const API_BASE_URL = process.env.REACT_APP_API_ROOT;

/**
 * 장비 대여 중인 api
 * @returns 장비 대여 중인 값
 */
export const getBorrowedEquipment = async () => {
  const endpoint = "/api/equipment/borrowed";
  const apiUrl = API_BASE_URL + endpoint;
  // console.log(apiUrl);
  return await axios.get(apiUrl);
  //호출되는 함수이므로 await를 사용하여 작성해야 한다.
};

export async function putReturnEquipment(data) {
  const endpoint = "/api/rental/return";
  const apiUrl = API_BASE_URL + endpoint;
  try {
    const response = await axios.put(apiUrl, data);
    return response;
  } catch (error) {
    console.error("에러:", error);
    throw error;
  }
}

/**
 * 장비 대여 가능한 api
 */
export const getAvailableEquipment = async () => {
  const endpoint = "/api/equipment/available";
  const apiUrl = API_BASE_URL + endpoint;
  return await axios.get(apiUrl);
};

/**
 * 유저 데이터 api
 * 부서에 대한 유저의 정보(이름)을 가져오는 거임.
 */
export const getUsersByDepartment = async (data) => {
  const endpoint = "/api/user?department_id=";
  const apiUrl = API_BASE_URL + endpoint;
  try {
    const response = await axios.get(apiUrl + data);
    return response;
  } catch (error) {
    console.error("에러:", error);
    throw error;
  }
};

/**
 * 대여하기(부서, 유저, 대여일)
 */

export async function postRentEquipment(data) {
  const endpoint = "/api/rental";
  const apiUrl = API_BASE_URL + endpoint;
  try {
    const response = await axios.post(apiUrl, data);
    return response;
  } catch (error) {
    console.error("에러:", error);
    throw error;
  }
}

/**
 *
 * @returns 부서 목록
 */
export const getDepartmentList = async () => {
  const endpoint = "/api/user/department";
  const apiUrl = API_BASE_URL + endpoint;
  try {
    const response = await axios.get(apiUrl);
    return response;
  } catch (error) {
    console.error("에러:", error);
    throw error;
  }
};
/**
 * 대여기록 api
 */
export async function getUserRentalRecordsByDepartment(data) {
  const endpoint = "/api/rental?userId=";
  const apiUrl = API_BASE_URL + endpoint;
  try {
    const response = await axios.get(apiUrl + data);
    return response;
  } catch (error) {
    console.error("에러:", error);
    throw error;
  }
}
